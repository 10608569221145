import styled from 'styled-components/macro';

export default styled.div`
  font-family: 'Roboto Mono', monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DotDotDot = styled.div`
`;
